import { useMatomo } from "@datapunt/matomo-tracker-react";
import React, { Fragment, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import AmazonAffiliate from "../adverts/AmazonAffiliate";
import AmazonAffiliateGroup from "../adverts/AmazonAffiliateGroup";
// import AdvertsBlock from "../adverts/AdvertBlock";
import AdvertOwnBlock from "../blocks/AdvertOwnBlock";
// import BacklinkProductBlock from "../blocks/BacklinkProductBlock";
import LastBlogPosts from "../posts/LastBlogPosts";
import NewsletterSubscribe from "./NewsletterSubscribe";
import { history } from '../..';
import { useStore } from "../../app/stores/store";
import SearchBox from "./SearchBox";

export default function HomePage() {

    const { enableLinkTracking, trackPageView } = useMatomo();
    enableLinkTracking();

    const { blogStore } = useStore();
    const { setPredicate } = blogStore;

    useEffect(() => {        
            trackPageView({
                documentTitle: 'Homepage'
            });
    }, [trackPageView])

    function handleSearch(search: string) {
        // console.log('SUCHBEGRIFF', search);
        setPredicate('searchParam', search);
        history.push("/artikel")
    }

    return (
        <Fragment>
            <Helmet>
                <title>Grillgewürze - mehr Freude am Grillvergnügen</title>      
                <meta name="description" content="Das Portal zum Thema Online-Marketing. Mehr Kunden, mehr Umsatz, mehr Erfolg durch Online-Marketing" />
                <meta name="keywords" content="grillgewürze, rub, rubs, bbq rub, gewürze" />
                <link rel="canonical" href={window.location.href} />
            </Helmet>
            <Row>
            <Col md={9}>
            <h1>Grillgewürze - mehr Freude beim Grillen</h1>
            <AdvertOwnBlock
                title='Mit den passenden Grillgewürzen zum ultimativen Grillvergnügen'
                content='<p>Sie haben ein tolles Produkt, eine nutzbringende Dienstleistung aber keiner besucht Ihre Webseite? Ihnen fehlen die Kunden und damit auch der Umsatz, um Ihr Online-Business weiter nach vorne zu bringen?</p><p>Die Entwicklung von Produkten und Dienstleistungen ist eine Seite der Medaille- die erfolgreiche Vermarktung dieser eine ganz andere Disziplin. Gerade im Online-Bereich existieren vielfältige Möglichkeiten, die wir in diesem Portal vorstellen.</p>'
                image='/banners/grillgewuerze-banner.png'
                imageTarget=''
                targets={[
                    // { linkText: 'Was ist Online Marketing?', linkTarget: '/was-ist-online-marketing' },
                    // { linkText: 'Gesund und Fit durch Fasten', linkTarget: '/erfolgreich-im-internet' },
                    // { linkText: 'Backlinks aufbauen', linkTarget: '/ihre-backlinks' },
                    // { linkText: 'Bewerben Sie Ihre Produkte', linkTarget: '/ihre-werbung' },
                ]}
            />

            {/* <AdvertsBlock advertCount={1} advertType={1} /> */}

            <LastBlogPosts articleCount={5} />
            <hr />
            {/* <div style={{marginBottom: '1em'}}></div> */}
            <AmazonAffiliateGroup adverts={
            [
                {
                    image: 'https://images-na.ssl-images-amazon.com/images/I/81CpA-R6rHS._SL1500_.jpg',
                    text: 'Lustiges Männergeschenk gefüllt mit Delikatessen wie Grill- und BBQ-Saucen, Texas BBQ Rub, Hot Chili Senf I Männer Geschenkidee zum Geburtstag',
                    link: 'https://www.amazon.de/dp/B08VD1T1YQ/ref=as_li_ss_tl?dchild=1&keywords=grillgew%C3%BCrze&qid=1624459268&sr=8-59&linkCode=ll1&tag=kayomo-21&linkId=9b136bc1187d883bbde709406133ebc1&language=de_DE',
                    title: 'Feinkost Herrenhandtasche'
                },
                {
                    image: 'https://images-na.ssl-images-amazon.com/images/I/817FNeNSX8L._SL1500_.jpg',
                    text: '5 erlesene Grillgewürze inkl. Rezepte, perfektes Grill Geschenk für Männer',
                    link: 'https://www.amazon.de/dp/B07TL8T5T8/ref=as_li_ss_tl?dchild=1&keywords=grillgew%C3%BCrze&qid=1624459268&sr=8-5&linkCode=ll1&tag=kayomo-21&linkId=ca1262823a6e35af607b30d44c754172&language=de_DE',
                    title: 'BBQ Grill Gewürze Geschenkset Männer'
                },
                {
                    image: 'https://images-na.ssl-images-amazon.com/images/I/812pIjn5tUS._SL1500_.jpg',
                    text: '100% Bio Grillgewürz für einen unvergesslichen Grillabend',
                    link: 'https://www.amazon.de/dp/B08CDT4772/ref=as_li_ss_tl?dchild=1&keywords=grillgew%C3%BCrze&qid=1624459268&sr=8-50&linkCode=ll1&tag=kayomo-21&linkId=c45b54ab074afb3e495829e16e5aef1b&language=de_DE',
                    title: 'BBQ Grillgewürze Geschenkset Männer'
                }
            ]
            }
            />

            

            </Col>
            <Col md={3}>
                <SearchBox updateSearch={handleSearch} />

                <NewsletterSubscribe />

                <AmazonAffiliate
                    image='https://images-na.ssl-images-amazon.com/images/I/519wxdn3%2BtL.jpg'
                    text='Grill Set, 6 Gewürze: Gewürze für Männer!'
                    link='https://www.amazon.de/dp/B07VS48JXW/ref=as_li_ss_tl?dchild=1&keywords=grillgew%C3%BCrze&qid=1624452645&sr=8-6&linkCode=ll1&tag=kayomo-21&linkId=2295c9e89bec300c40208cd6bdb39213&language=de_DE'
                    title='Ankerkraut Grillgewürze'
                />

                {/* <BacklinkProductBlock /> */}

                {/* <AdvertsBlock advertCount={1} advertType={2} /> */}
            </Col>
            </Row>
        </Fragment>
    )
}