import { faAmazon } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card, CardDeck, CardGroup } from "react-bootstrap";

interface advertData {
    title: string;
    link: string;
    image: string;
    text: string;
}

interface Props {
    adverts: advertData[]
}

export default function AmazonAffiliateGroup(aff: Props) {

    function openLink(url: string) {
        window.open(
            url,
            '_blank'
          );
    }

    return (
        <CardGroup>
        {aff.adverts.map((advert: advertData, i) => (
            <Card key={i} >
                <Card.Img variant="top" src={advert.image} title={advert.title} onClick={() => openLink(advert.link)} />
                <Card.Body>
                    <Card.Title>{advert.title}</Card.Title>
                    {/* <Card.Text style={{fontSize: 'small'}}>{advert.text}</Card.Text> */}
                    <Button variant="warning" block href={advert.link} target='_blank'><FontAwesomeIcon icon={faAmazon} style={{ marginRight: '0.5em' }} />Mehr Informationen</Button>                   
                </Card.Body>
            </Card>
        ))}
        </CardGroup>
    )
}